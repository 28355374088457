import { keysOf } from '@unbounded/unbounded-components'
import type CCXT from 'ccxt'
import type { binance, Dictionary, Market, Tickers } from 'ccxt'
import { differenceInSeconds } from 'date-fns'
import type { Backend } from '~api/common/backend'
import type { ExchangeMeta, TradingMarketBundle } from '~api/exchange/types'
import { ExchangeType } from '~data/exchanges'
import { loadScript } from '~utils/loadScript'
import { captureError } from '~utils/monitoring'
import { CombinedSignal, ImmutableSignal, Signal } from '~utils/signal'
import { sleep } from '~utils/waitUntil'
import DemoExchange from './demoExchange'

export type ExchangeInstanceType = binance | DemoExchange

const INITIAL_EXCHANGE_META: ExchangeMeta = {
  isLoadingMarkets: false,
  marketsLastUpdate: 0,
  marketsLastManualUpdate: 0,
}

const MARKETS_UPDATE_AGE_SECONDS = 60

// ALWAYS keep the version in sync with one in package.json otherwise (or TS typings will go out of sync)
const CCXT_JSDELIVR_URL = 'https://cdn.jsdelivr.net/npm/ccxt@4.3.58/dist/ccxt.browser.min.js'
const CCXT_UNPKG_URL = 'https://unpkg.com/ccxt@4.3.58/dist/ccxt.browser.min.js'

export default class ExchangeBackend {
  private isCcxtLoaded = false

  private readonly parentBackend: Backend

  private exchanges: Partial<Record<ExchangeType, ExchangeInstanceType>> = {}

  private exchangeLoadPromise: Partial<Record<ExchangeType, Promise<ExchangeInstanceType>>> = {}

  private exchangesMarkets: Record<ExchangeType, Signal<TradingMarketBundle[] | undefined>> = {
    [ExchangeType.demo]: new Signal<TradingMarketBundle[] | undefined>(undefined),
    [ExchangeType.binance]: new Signal<TradingMarketBundle[] | undefined>(undefined),
    [ExchangeType.kraken]: new Signal<TradingMarketBundle[] | undefined>(undefined),
    [ExchangeType.kucoin]: new Signal<TradingMarketBundle[] | undefined>(undefined),
    [ExchangeType.gateio]: new Signal<TradingMarketBundle[] | undefined>(undefined),
  }

  private exchangesMeta: Record<ExchangeType, Signal<ExchangeMeta>> = {
    [ExchangeType.demo]: new Signal(INITIAL_EXCHANGE_META),
    [ExchangeType.binance]: new Signal(INITIAL_EXCHANGE_META),
    [ExchangeType.kraken]: new Signal(INITIAL_EXCHANGE_META),
    [ExchangeType.kucoin]: new Signal(INITIAL_EXCHANGE_META),
    [ExchangeType.gateio]: new Signal(INITIAL_EXCHANGE_META),
  }

  constructor(parentBackend: Backend) {
    this.parentBackend = parentBackend
    this.loadCcxtLibrary()
  }

  // TODO change to dynamic import of "node_modules/ccxt/dist/ccxt.browser.min.js"
  // https://hacera.atlassian.net/browse/PASA-482
  private async loadCcxtLibrary(url = CCXT_JSDELIVR_URL, isRetry = false): Promise<typeof CCXT | undefined> {
    if (this.isCcxtLoaded) {
      return window.ccxt! as typeof CCXT
    }

    try {
      await loadScript(`ccxt-script-loader${isRetry ? '-retry' : ''}`, url)

      this.isCcxtLoaded = true

      return window.ccxt! as typeof CCXT
    } catch (e) {
      captureError(e, { label: `Error loading CCXT script from ${url}` })
    }

    if (!isRetry) {
      return this.loadCcxtLibrary(CCXT_UNPKG_URL, true)
    }

    return undefined
  }

  private formatExchangeMarkets(exchange: ExchangeInstanceType, tickers: Tickers): TradingMarketBundle[] {
    return Object.values((exchange.markets || {}) as Dictionary<Market>)
      .filter(marketRaw => marketRaw?.symbol && marketRaw?.type === 'spot')
      .map(marketRaw => {
        const ticker = marketRaw ? tickers?.[marketRaw.symbol] || exchange.tickers[marketRaw.symbol] : undefined

        return {
          symbol: marketRaw!.symbol,
          baseAsset: marketRaw!.base,
          quoteAsset: marketRaw!.quote,
          precision: marketRaw!.precision,
          market: marketRaw!,
          ticker,
        }
      })
  }

  private async loadExchangeWithMarkets(instance: ExchangeInstanceType, exchangeType: ExchangeType): Promise<ExchangeInstanceType> {
    this.exchangesMeta[exchangeType].current = { ...this.exchangesMeta[exchangeType].current, isLoadingMarkets: true }

    try {
      const shouldLoadMarkets = !instance.markets || Object.keys(instance.markets).length === 0
      const [, tickers] = await Promise.all([shouldLoadMarkets ? instance.loadMarkets() : Promise.resolve(), instance.fetchTickers()])

      this.exchangesMarkets[exchangeType].current = this.formatExchangeMarkets(instance, tickers)
    } catch (error) {
      captureError(error, { label: `Error loading trading markets or tickers for ${exchangeType}` })
    }

    this.exchangesMeta[exchangeType].current = {
      isLoadingMarkets: false,
      marketsLastUpdate: +new Date(),
      marketsLastManualUpdate: +new Date(),
    }

    return instance
  }

  async loadExchange(exchangeType: ExchangeType): Promise<ExchangeInstanceType | undefined> {
    const existingInstance = this.exchanges[exchangeType]
    if (existingInstance) {
      return existingInstance
    }

    const ccxt = await this.loadCcxtLibrary()

    if (!ccxt) {
      return undefined
    }

    let instance
    let isSandboxMode = false

    switch (exchangeType) {
      case ExchangeType.demo:
        instance = new DemoExchange(this.parentBackend)
        break
      case ExchangeType.binance:
        isSandboxMode = true
        // eslint-disable-next-line new-cap
        instance = new ccxt.binance() as binance
        break
      default:
        throw new Error(`unknown exchange type: ${exchangeType}`)
    }

    if (isSandboxMode) {
      instance.setSandboxMode(true)
    }

    if (this.exchangeLoadPromise[exchangeType]) {
      return this.exchangeLoadPromise[exchangeType]
    }

    // Load each exchange `markets` and `tickers` right from the initial exchange load, we need it everywhere
    // We save the promise, so it can be cached if `loadExchange` is invoked from the multiple places simultaneously
    this.exchangeLoadPromise[exchangeType] = this.loadExchangeWithMarkets(instance, exchangeType)
    this.exchanges[exchangeType] = await this.exchangeLoadPromise[exchangeType]

    return this.exchanges[exchangeType]
  }

  async loadExchanges<T extends ExchangeType[]>(exchangeTypes: T) {
    const instancesRaw = await Promise.allSettled(exchangeTypes.map(async exchangeType => this.loadExchange(exchangeType)))

    return instancesRaw.map(result => (result.status === 'fulfilled' ? result.value : undefined))
  }

  getExchangeMarkets(exchangeType?: ExchangeType) {
    if (!exchangeType) {
      return new ImmutableSignal<TradingMarketBundle[] | undefined>(undefined)
    }

    this.loadExchange(exchangeType)

    return this.exchangesMarkets[exchangeType]
  }

  getExchangesMarkets(exchangeTypes: ExchangeType[]) {
    this.loadExchanges(exchangeTypes)

    const getMarkets = () =>
      exchangeTypes.reduce<Partial<Record<ExchangeType, TradingMarketBundle[] | undefined>>>((acc, exchangeType) => {
        const markets = this.exchangesMarkets[exchangeType]
        if (exchangeTypes.includes(exchangeType)) {
          acc[exchangeType] = markets.current
        }
        return acc
      }, {})

    return new CombinedSignal(
      getMarkets,
      keysOf(this.exchangesMarkets)
        .filter(exchangeType => exchangeTypes.includes(exchangeType))
        .map(exchangeType => this.exchangesMarkets[exchangeType]),
    )
  }

  getExchangeMeta(exchangeType?: ExchangeType) {
    if (!exchangeType) {
      return new ImmutableSignal<ExchangeMeta>(INITIAL_EXCHANGE_META)
    }

    return this.exchangesMeta[exchangeType]
  }

  getExchangesMeta(exchangeTypes: ExchangeType[]) {
    const getMetas = () =>
      exchangeTypes.reduce<Partial<Record<ExchangeType, ExchangeMeta>>>((acc, exchangeType) => {
        const meta = this.exchangesMeta[exchangeType]
        if (exchangeTypes.includes(exchangeType)) {
          acc[exchangeType] = meta.current
        }
        return acc
      }, {})

    return new CombinedSignal(
      getMetas,
      keysOf(this.exchangesMeta)
        .filter(exchangeType => exchangeTypes.includes(exchangeType))
        .map(exchangeType => this.exchangesMeta[exchangeType]),
    )
  }

  async fetchExchangeMarkets(exchangeType: ExchangeType, isManual = false): Promise<TradingMarketBundle[]> {
    const exchange = await this.loadExchange(exchangeType)

    if (!exchange) {
      return []
    }

    // We allow to update only once per minute, otherwise user could be just banned from Binance (or other exchange) API.
    const { marketsLastUpdate } = this.exchangesMeta[exchangeType].current
    const shouldUpdate = differenceInSeconds(+new Date(), marketsLastUpdate || +new Date()) > MARKETS_UPDATE_AGE_SECONDS

    if (shouldUpdate) {
      await this.loadExchangeWithMarkets(exchange, exchangeType)
    } else if (isManual) {
      // Timeout for artificial UI loading state in case of user tried
      // to refresh prices manually. We just pretend it's refreshing
      this.exchangesMeta[exchangeType].current = { ...this.exchangesMeta[exchangeType].current, isLoadingMarkets: true }
      await sleep(1000)

      // We always update `marketsLastManualUpdate` for UI, so user will think it was updated. :evilla:
      this.exchangesMeta[exchangeType].current = {
        isLoadingMarkets: false,
        marketsLastUpdate,
        marketsLastManualUpdate: +new Date(),
      }
    }

    return this.exchangesMarkets[exchangeType]?.current || []
  }

  async fetchUpdateExchangesMarkets(exchangeTypes: ExchangeType[], isManual = false) {
    await Promise.allSettled(exchangeTypes.map(async exchangeType => this.fetchExchangeMarkets(exchangeType, isManual)))
  }
}

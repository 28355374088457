import type { Backend, BackendCellEmbedConnection, BackendCellEmbedConnectionListeners, BackendCellEmbedConnectionUpdate } from '../../common/backend'
import { BackendCellEmbedConnectionMode } from '../../common/backend'
import PhoenixConnection, { PhoenixConnectionListeners } from './phoenix-connection'

interface Listeners extends BackendCellEmbedConnectionListeners, PhoenixConnectionListeners<{}> {}

export default class MiPasaCellEmbedConnection extends PhoenixConnection<Listeners, {}> implements BackendCellEmbedConnection {
  constructor(backend: Backend, projectId: string, fileId: string, cellId: string, outputIndex = '0', mode = BackendCellEmbedConnectionMode.latest) {
    super(backend, `/api/mipasa/socket`, `embedded_cell:${cellId}`, {
      project_id: projectId,
      file_id: fileId,
      cell_output_index: outputIndex,
      mode,
    })
  }

  async connect() {
    if (this.channel) {
      this.disconnect()
    }

    super.connect()
  }

  async fetchCellOutputPage(page: number, pageSize: number) {
    return this.sendSynchronousCommand<BackendCellEmbedConnectionUpdate>('fetch_cell_output_page', { page, page_size: pageSize })
  }
}

export enum ExchangeType {
  demo = 'demo',
  binance = 'binance',
  kraken = 'kraken',
  kucoin = 'kucoin',
  gateio = 'gateio',
}

export interface SupportedExchange {
  id: ExchangeType
  name: string
  icon: string
  logo: string
  isComingSoon?: boolean
}
